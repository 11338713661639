import React, { Component } from 'react'
import { Form, Page, Box, Icons } from 'components'
import { getMatchParams, navigate } from 'shared/router'
import { ShippingParcelCreateRequest } from 'api/types'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import InboundShipments from 'api/InboundShipments'
import Moment from 'moment'

export default class CustomInboundCreate extends Component {
  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  submit = async (data) => {
    if (Number.isNaN(Number(data.expectedQuantity)) || Number(data.expectedQuantity) <= 0) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.invalid_expected_quantity),
        status: 'error',
      })
      return
    }
    let state: any = {
      ddtCode: data.ddtCode,
      expectedQuantity: Number(data.expectedQuantity),
      supplierCode: data.supplierCode,
      ddtDate: Moment(data.ddtDate).format('DD.MM.yyyy'),
    }
    try {
      if (this.operation?.id) {
        const request: ShippingParcelCreateRequest = {
          configurationId: this.operation.id,
          attributes: {
            ...state,
          },
        }

        const response = await InboundShipments.createParcel(request)
        if (!response || !response?.header?.parcelCode) throw new Error('No Parcel created') // Never shown to user

        state = {
          ...state,
          shipmentCode: response?.header?.shippingCode,
          parcelCode: response?.header?.parcelCode,
          destination: data.destination,
        }
        if (this.operation.hasConveyor) {
          navigate(
            '/custom/inbound/gate/:configCode/reading/:parcelCode',
            { parcelCode: state.parcelCode, configCode: this.operation?.code },
            { state }
          )
        } else {
          navigate(
            '/custom/inbound/:configCode/reading/:parcelCode',
            { parcelCode: state.parcelCode, configCode: this.operation?.code },
            { state }
          )
        }
      }
    } catch (error) {
      showToast({ status: 'error', title: error.message })
    }
  }

  onBackPress = () => {
    navigate(`/`)
  }

  render() {
    return (
      <Page title={this.operation?.description ?? 'Inbound'} onBackPress={this.onBackPress}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  label: __(T.misc.ddt),
                  name: 'ddtCode',
                  image: <Icons.Barcode />,
                  focus: true,
                  required: true,
                },
                {
                  label: __(T.fields.quantity),
                  name: 'expectedQuantity',
                  required: true,
                },
                {
                  label: __(T.misc.supplier),
                  name: 'supplierCode',
                  required: true,
                },
                {
                  label: __(T.misc.ddt_date),
                  name: 'ddtDate',
                  type: 'date',
                  required: true,
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
