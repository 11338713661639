import React, { Component } from 'react'
import {
  Page,
  GroupedProductList,
  Spacer,
  TagCounter,
  Box,
  TextBox,
  EmulateButton,
  Button,
  Icons,
  ItemsStatesRow,
} from 'components'
import { GroupedShipmentProduct, ShipmentParcel, TmrItem, TmrZone } from 'api/types'
import { navigate, getMatchParams } from 'shared/router'
import { askUserConfirmation, showToast } from 'shared/utils'
import ShipmentProvider, { CheckListType } from 'ShipmentProvider'
import { T, __, __UP } from 'translations/i18n'
import Shipments from 'api/Shipments'
import Gate from 'api/Gate'
import { PageParams } from 'pages'
import Sounds from 'shared/Sounds'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import styled from '@emotion/styled'
import Items from 'api/Items'
import * as Onde from 'assets/onde.json'
import Lottie from 'react-lottie'
import CustomInboundProvider from 'CustomInboundProvider'
import AppStore from 'AppStore'

interface Params extends PageParams {
  inboundZone?: TmrZone
  parcelCode: string
}

interface MatchParams {
  configCode: string
  parcelCode: string
}

interface State {
  parcels: ShipmentParcel[]
  checkListType: CheckListType
  groupedProducts: GroupedShipmentProduct[]
  loading: boolean
  reading: boolean
}

export default class extends Component<{}, State> {
  unexpectedFound = false

  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  params: Params = {
    inboundZone: AppStore.getZonesByType('STOCK')[0],
    ...getMatchParams(this.props),
  }

  state: State = {
    parcels: [],
    checkListType: 'ITEMS',
    groupedProducts: [],
    loading: true,
    reading: false,
  }

  async componentDidMount() {
    try {
      if (!this.params.inboundZone) throw new Error(__(T.error.no_stock_zones))
      if (!this.operation) throw new Error(__(T.error.no_operation_config))
      if (this.operation.readingMode !== 'rfid') throw new Error(__(T.error.not_supported_reading_mode))

      const {
        checkListType,
        inboundShipmentParcels,
        groupedProducts,
      } = await ShipmentProvider.fetchInboundShipmentParcels({ parcelCodes: [this.params.parcelCode] })

      Gate.initSSE(this.onTagRead, this.onError)

      const inboundRequest = CustomInboundProvider.getGateInboundRequest(this.params.parcelCode, [], [])

      await Gate.startInbound(inboundRequest)

      this.setState({ loading: false, parcels: inboundShipmentParcels, groupedProducts, reading: true })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  componentWillUnmount() {
    Gate.closeEventSource()
    //Gate.emergencyStop() // TODO: questo va fatto?
  }

  onTagRead = (event: any) => {
    const data = JSON.parse((event.data as string).replace(/'/g, '"'))

    this.onDecodedItemCallback(data.epc)
  }

  onError = (event: any) => {
    const data = JSON.parse((event.data as string).replace(/'/g, '"'))
    showToast({ status: 'error', title: `${__(T.error.error)}: ${data.code}`, description: data.message })
  }

  decodeFunction = (epcs: string[]) => {
    if (!this.operation) throw new Error(__(T.error.undefined_operation_config))

    return Shipments.batchValidate<any>({
      configurationId: this.operation.id,
      identifiers: epcs,
    })
  }

  onDecodedItemCallback = async (epc: string) => {
    const { parcels: inboundShipmentParcels, groupedProducts } = this.state
    const items: TmrItem[] = inboundShipmentParcels[0].expectedItems.filter(
      (itm) => itm?.itemIdentifiers?.find((itmId) => itmId.code === epc) !== undefined
    )

    if (!items || items.length === 0) {
      const item = await Items.decode(epc)
      if (item.id && item.itemIdentifiers) items.push(item as TmrItem)
    }

    ShipmentProvider.processInboundItemsStates(items, this.operation)

    ShipmentProvider.processItemForItemsChecklist(items, inboundShipmentParcels, groupedProducts)

    const counters = ShipmentProvider.getCounters(inboundShipmentParcels[0], this.state.checkListType)

    if (!this.unexpectedFound && counters.unexpected > 0) {
      this.unexpectedFound = true
      Sounds.error()
    }

    if (counters.detected === counters.expected && counters.unexpected === 0) {
      Sounds.success()
    }

    this.forceUpdate()
  }

  clear = () => {
    const { parcels: inboundShipmentParcels } = this.state
    this.unexpectedFound = false
    const groupedProducts = ShipmentProvider.clearAllReceivingReadings(inboundShipmentParcels)
    this.setState({ groupedProducts })
  }

  saveParcel = async () => {
    await this.confirmInbound(false)
  }

  saveAndConfirm = async () => {
    await this.confirmInbound(true)
  }

  confirmInbound = async (confirmParcel: boolean) => {
    const { parcels: inboundShipmentParcels, checkListType } = this.state
    const parcel = inboundShipmentParcels.length ? inboundShipmentParcels[0] : undefined
    const totalExpectedQuantity = Number(parcel?.header?.parcelAttributes?.expectedQuantity ?? 0)
    const { detected } = ShipmentProvider.getCounters(
      parcel,
      checkListType,
      checkListType === 'UPCS' ? 'ITEMS' : checkListType
    )
    const unexpected = detected > totalExpectedQuantity ? detected - totalExpectedQuantity : 0

    try {
      if (
        parcel?.detectedItems?.filter((item) =>
          item.__processedStates?.map((state) => state.processedState)?.includes('ERROR')
        ).length
      )
        throw new Error(__(T.error.items_in_error_found))

      if (detected < totalExpectedQuantity || detected > totalExpectedQuantity || unexpected > 0) {
        const confirmed = confirmParcel
          ? await askUserConfirmation(__(T.confirm.discrepancy_title), __(T.confirm.discrepancy_message))
          : await askUserConfirmation(__(T.confirm.save_parcel_readings), __(T.confirm.save_parcel_readings_message))

        if (!confirmed) return
      }

      if (confirmParcel) {
        parcel?.header?.parcelCode && (await Gate.closeInbound(parcel?.header.parcelCode))
      }

      await CustomInboundProvider.confirmInboundIgnoringUnexpected(
        inboundShipmentParcels,
        this.operation!.id,
        this.params.inboundZone!.id,
        checkListType,
        confirmParcel
      )
      showToast({
        title: __(T.misc.success),
        description: confirmParcel ? __(T.messages.inbound_success) : __(T.messages.operation_success),
        status: 'success',
      })

      if (confirmParcel) {
        this.navigateBack()
      }
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  navigateBack = () => navigate('/custom/inbound/:configCode/create', { configCode: this.operation?.code })

  toggleGate = async () => {
    const { reading } = this.state
    try {
      if (reading) {
        const confirmed = await askUserConfirmation(__(T.misc.alarm), __(T.confirm.confirm_stop_gate))
        if (!confirmed) return
        await Gate.emergencyStop()
      } else {
        await Gate.resume()
      }
      this.setState({ reading: !this.state.reading })
    } catch (error) {
      showToast({ status: 'error', title: __(T.error.error), description: error.message })
    }
  }

  emulateTags1 = async () => {
    await Gate.emulateTags({ epcs: ['3035EB98C80905C00000002A', '3035EB98C80905C00000002B'], delay: 3000 })
  }

  emulateTags2 = async () => {
    await Gate.emulateTags({ epcs: ['3035EB98C813538000000007'], delay: 3000 })
  }

  emulateTags3 = async () => {
    await Gate.emulateTags({ epcs: ['3035EBBC48308540000000D3'], delay: 3000 })
  }

  emulate = async (epcs: string[]) => {
    await Gate.emulateTags({ epcs: epcs, delay: 1000 })
  }

  renderIcon = () => {
    const style = {
      marginRight: 10,
      width: 36,
      height: 36,
    }
    const { reading } = this.state
    if (reading) {
      return <Icons.Pause style={style} />
    }
    return <Icons.Play style={style} />
  }

  renderGateButton = () => {
    const { reading } = this.state
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: (Onde as any).default,
      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }
    return (
      <Container row>
        <ButtonControl
          style={{ backgroundColor: reading ? '#EF4141' : '#ceff00' }}
          row
          center
          onClick={() => this.toggleGate()}
        >
          {this.renderIcon()}
          {reading ? __UP(T.misc.alarm) : __UP(T.misc.start)}
        </ButtonControl>
        {reading && (
          <div style={{ padding: '5px 10px' }}>
            <Lottie isClickToPauseDisabled style={{ width: '100%', height: '100%' }} options={defaultOptions} />
          </div>
        )}
      </Container>
    )
  }

  removeItemFromReadings = (item: TmrItem) => {
    const { parcels: inboundShipmentParcels, groupedProducts } = this.state
    ShipmentProvider.removeItemsForItemsChecklist([item], inboundShipmentParcels, groupedProducts)
    this.forceUpdate()
  }

  render() {
    const { parcels: inboundShipmentParcels, groupedProducts, loading } = this.state
    const parcel = inboundShipmentParcels.length ? inboundShipmentParcels[0] : undefined
    const ddtCode = parcel?.header?.parcelAttributes?.ddtCode
    const supplier = parcel?.header?.parcelAttributes?.supplierCode
    const totalExpectedQuantity = Number(parcel?.header?.parcelAttributes?.expectedQuantity ?? 0)
    const { detected } = ShipmentProvider.getCounters(parcel, 'ITEMS')
    const unexpected = detected > totalExpectedQuantity ? detected - totalExpectedQuantity : 0
    const error = unexpected > 0 ? __(T.error.rfid_discrepancies_found) : ''
    const pageTitle = this.operation?.description ?? __(T.titles.inbound)

    return (
      <Page
        title={pageTitle}
        onBackPress={this.navigateBack}
        loading={loading}
        header={{
          details: [
            { label: __(T.misc.ddt), value: ddtCode },
            { label: __(T.misc.supplier), value: supplier },
          ],
        }}
        enableEmulation
        emulationFunction={this.emulate}
      >
        <Page.Sidebar style={{ overflow: 'auto' }}>
          <Box flex style={{ overflow: 'auto' }}>
            <TagCounter detected={detected} expected={totalExpectedQuantity} unexpected={unexpected} />
            {this.renderGateButton()}
            <Spacer />
            <TextBox text={error} type="error" />
          </Box>
          <Button title={__(T.misc.save_parcel)} onClick={this.saveParcel} variant="default" />
          <Spacer />
          <Button title={__(T.misc.confirm_parcel)} onClick={this.saveAndConfirm} />
        </Page.Sidebar>

        <Page.Content>
          <ItemsStatesRow
            items={parcel?.detectedItems as TmrItem[]}
            onItemDeleteCallback={this.operation.removeMode !== 'none' ? this.removeItemFromReadings : undefined}
          />
          <GroupedProductList
            loading={loading}
            data={groupedProducts}
            noChecklist={this.operation.hasChecklist === 'no'}
          />

          <Box>
            <EmulateButton title="Emulate tags 1" onPress={this.emulateTags1} />
            <EmulateButton title="Emulate tags 2" onPress={this.emulateTags2} />
            <EmulateButton title="Emulate tag KO" onPress={this.emulateTags3} />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}

const ButtonControl = styled(Box)`
  flex: 1;
  background-color: #ceff00;
  border-radius: 50px;
  padding: 15px 25px;
  cursor: pointer;
  font-weight: 900;
  font-size: 22px;
`

const Container = styled(Box)`
  background-color: white;
  border: solid 2px #eeeeee;
  border-radius: 50px;
  padding: 10px;
`
