import InboundShipments from 'api/InboundShipments'
import { GateInboundRequest, QualityControlEntry, ShipmentParcel, ShipmentParcelItem } from 'api/types'
import ShipmentProvider, { CheckListType } from 'ShipmentProvider'

export default class CustomInboundProvider {
  static async confirmInboundIgnoringUnexpected(
    shipmentParcels: ShipmentParcel[],
    configurationId: string,
    inboundZoneId: string,
    checkListType: CheckListType = 'ITEMS',
    confirmParcel: boolean
  ) {
    const shippingRequestParcels = ShipmentProvider.getShippingRequestParcelsFromShipmentParcels(
      shipmentParcels,
      configurationId,
      undefined,
      checkListType
    )
    for (let i = 0; i < shippingRequestParcels.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await InboundShipments.updateReadings(
        shippingRequestParcels[i].parcelCode,
        shippingRequestParcels[i].shippingParcelDetail
      )
      // eslint-disable-next-line no-await-in-loop
      if (confirmParcel) {
        // eslint-disable-next-line no-await-in-loop
        await InboundShipments.confirmParcel(shippingRequestParcels[i].parcelCode, { zone: inboundZoneId })
      }
    }
  }

  static getGateInboundRequest(parcelCode: string, items: ShipmentParcelItem[], qcEntries: QualityControlEntry[]) {
    if (!parcelCode || !items || !qcEntries) throw new Error('Cannot create inbound request')

    const request: GateInboundRequest = {
      parcelCode: parcelCode,
      items: [],
      qcEntries: qcEntries,
    }

    items.forEach((item) => {
      const key = `${item.product?.style?.value ?? ''}|${item.product?.color?.value ?? ''}|${
        item.product?.size?.value ?? ''
      }`
      const epc = item.itemIdentifiers.find((id) => id.identifierType === 'UHFTag')
      if (key && epc)
        request.items.push({
          epc: epc.code,
          qcKey: key,
        })
    })

    return request
  }
}
