import React from 'react'
import { EmptyListMessage, GroupedProductRow } from 'components'
import { GroupedShipmentProduct, TmrProduct } from 'api/types'
import { T, __ } from 'translations/i18n'

interface Props {
  data: GroupedShipmentProduct[]
  emptyMessage?: string | JSX.Element
  style?: React.CSSProperties
  noChecklist?: boolean
  loading?: boolean
  onDeleteRowCallback?: (product: TmrProduct) => void
}

export default function GroupedProductList({
  data,
  emptyMessage = <span style={{ whiteSpace: 'break-spaces' }}>{__(T.messages.press_start_to_read)}</span>,
  style,
  noChecklist,
  loading = false,
  onDeleteRowCallback,
}: Props) {
  if (!data) return null

  function sortFn(a: GroupedShipmentProduct) {
    if (a.unexpected > 0) return -1
    if (a.expected === a.detected) return 1
    return 0
  }

  if (loading) {
    return (
      <>
        <GroupedProductRow.Skeleton />
        <GroupedProductRow.Skeleton />
      </>
    )
  }

  if (!data || data.length === 0) return <EmptyListMessage center>{emptyMessage}</EmptyListMessage>

  return (
    <>
      {data.length > 0 &&
        data
          .sort(sortFn)
          .map((prod) => (
            <GroupedProductRow
              key={prod.product.code}
              product={prod.product}
              detected={prod.detected}
              expected={prod.expected}
              unexpected={prod.unexpected}
              noChecklist={noChecklist}
              onDeleteCallback={onDeleteRowCallback}
            />
          ))}
    </>
  )
}
