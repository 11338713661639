import gateApi, { setPort } from './gateApi'
import { GateInboundRequest } from './types'

export default class Gate {
  static eventSource: EventSource

  static emulateTags(data: { epcs: string[]; delay?: number }) {
    setPort('emulation')
    return gateApi.post('/emulateTags', data)
  }

  static resume() {
    setPort('main')
    return gateApi.post('/resume')
  }

  static emergencyStop() {
    setPort('main')
    return gateApi.post('/alarm')
  }

  static startInbound(inboundRequest: GateInboundRequest) {
    setPort('main')
    return gateApi.post('/startInbound', inboundRequest)
  }

  static closeInbound(parcelCode: string) {
    setPort('main')
    return gateApi.post(`/closeInbound/${parcelCode}`)
  }

  static initSSE(onTagReadCallback?: (event: any) => void, onErrorCallback?: (event: any) => void) {
    setPort('sse')
    this.eventSource = new EventSource(`${gateApi.getBaseURL()}/sse`)
    onTagReadCallback && this.eventSource.addEventListener('onTagRead', onTagReadCallback)
    onErrorCallback && this.eventSource.addEventListener('onError', onErrorCallback)
  }

  static closeEventSource() {
    this.eventSource.close()
  }
}
